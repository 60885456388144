import React, { useState, useEffect } from 'react';
import './Typewriter.css'; // Importujemy plik CSS z nową klasą
import { useTranslation } from 'react-i18next';
import {Link} from 'react-scroll'

const Typewriter = ({ text, speed }) => {
  const [displayText, setDisplayText] = useState('');
  const [animationComplete, setAnimationComplete] = useState(true);
  const {t, i18n} = useTranslation();

useEffect(() => {
  const lng = navigator.language;
  i18n.changeLanguage(lng);
  
}, []);

  return (
    <div className='slide-in-bottom' style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "262px"}}>
      <h1 className='hero__title fade-in' style={{marginBottom: "0", lineHeight: ".95"}}>{t("hero_title")}</h1>
      <div className={`hero__text-container2 }`}>
        <p className={`fade-in desc_mobile`} style={{textAlign: "center"}}>{t("hero_desc_mobile")}</p>
        <p className={`fade-in desc_classic`} style={{textAlign: "center"}}>{t("hero_desc")}</p>
        <div className={`svg-container ${animationComplete ? 'visible' : ''}`}>
          <Link 
              to="about" 
              spy={true} 
              smooth={true} 
              offset={-180} 
              duration={500}
            >
            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 13.5L12 16.5L15 13.5" stroke="#fff" />
              <path d="M9 7.5L12 10.5L15 7.5" stroke="#fff" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
  
}
export default Typewriter;
