import React, { useEffect, useState } from 'react'
import Navbar from './navbar'
import Typewriter from './typewriter'
import { CSSTransition } from 'react-transition-group';
import SimpleSlider from './simpleSlider';
import { useTranslation } from 'react-i18next';

export default function Hero() {

  const {t, i18n} = useTranslation();

useEffect(() => {
  const lng = navigator.language;
  i18n.changeLanguage(lng);
  
}, []);

const lng = navigator.language;
    const textToType = "We create a future of the legal industry!";
    const typingSpeed = 50; 

    const [images, setImages] = useState([]);

    const handleShowImages = () => {
      const newImages = [1, 2, 3]; // Przykładowa lista obrazków
      setImages(newImages);
    };
  
    const dataArray = [1, 2, 3];

    const valueCircles = [
      { name: 'Legal design', top: '20%', left: '50%' },
      { name: 'Paperless', top: '80%', left: '50%' },
      { name: 'Simplicity', top: '50%', left: '80%' },
      { name: 'Practical innovation', top: '50%', left: '20%' }
    ];


  return (
    <div className='hero' style={{ 
      backgroundImage: 'linear-gradient(rgba(36, 51, 92, 0.8), rgba(36, 51, 92, 0.8)), url("/hero-image.jpg")',
      backgroundSize: 'cover',
      width: '100%',
      backgroundPosition: '50% 20%'
  }}>
        <div className='hero__container' id="hero">

        <div className='hero__text-container' style={{color: '#F8F8F8', display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <Typewriter text={textToType} speed={typingSpeed} />
          
        </div>

      
        </div>
       
    </div>
  )
}
