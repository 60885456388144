import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          nav_about: "About us",
          nav_projects: "Projects",
          nav_offer: "Offer",
          nav_contact: "Contact",
          hero_title: "We create a future of the legal industry!",
          hero_desc: "Discover new opportunities with our innovative technology solutions, designed to meet the needs of the legal industry, which is constantly evolving and requires agile adaptation to ongoing changes and growing customer expectations. Explore our products and open the door to a legal world that is simple, accessible and available to everyone.",
          hero_desc_mobile: "Discover new opportunities with our innovative technology solutions, designed to meet the needs of the legal industry, which is constantly evolving and requires agile adaptation to ongoing changes and growing customer expectations.",
          about_title: "About us",
          about_text1: "At Encoded Legal Solutions, creativity meets technology, creating solutions that are intuitive for every user of our applications. Our passionate team not only integrates advanced artificial intelligence technologies, but also ensures that they have practical use which increases the quality and the speed of daily work.",
          about_text3: "The solutions which we offer are aimed to a variety of clients, including law firms and corporate legal departments, as well as individuals who are not involved in law on a daily basis, but who are looking for simple way to function on a daily basis in an intricate world full of complicated legal regulations.", 
          about__found_title: "Our foundations",
          about_btn_ld: "Legal design",
          about_btn_pa: "Paperless",
          about_btn_si: "Simplicity",
          about_btn_prac: "Practicality",
          about_desc_ld: "We want to provide tools which enable their users to create clear and understandable legal documents in compliance with current regulations. Unlike many current legal agreements, which are often characterized by vagueness and intricacy, we focus on simplicity and clarity.",
          about_desc_pa: "We offer tools which enable our clients to store, process and sign documents digitally, thus saving employees' time, reducing costs associated with the company's documentation workflow and reducing the negative impact on the environment.",
          about_desc_si: "In our approach to software design, we prioritize simplicity. We offer intuitive and easy-to-use interfaces, free of unnecessary functionality. We strive to provide a pleasant and seamless experience for every user, regardless of their experience level.",
          about_desc_prac: "We focus on specific innovations that directly benefit users. Our goal is to provide practical solutions that effectively solve current problems and significantly improve workflow. We don't get caught up in the technological hype, dealing with developing technologies that really matter to our customers.",
          projects_title: "Our projects",
          projects_vt_title: "Verifytool",
          projects_vt_desc: "Elevate decision-making with verifytool.io, our AI-powered tool delivering comprehensive company information, including vital financial data. Gain a competitive edge through efficient business intelligence analysis.",
          projects_lh_title: "Legal Hire Network",
          projects_lh_desc: "Legal Hire Network is a specialized portal catering exclusively to the legal industry. It revolutionizes recruitment and collaboration by offering a platform for outsourcing legal matters to highly specialized law firms.",
          projects_btn: "Learn more",
          offer_title: "Our offer",
          offer_wa: "Web Application Development",
          offer_wa_desc: "Our team excels in creating dynamic, user-centric web applications tailored to the specific requirements of the legal industry.",
          offer_mb: "Mobile Application Development",
          offer_mb_desc: "We specialize in crafting intuitive and feature-rich mobile applications for both Android and iOS platforms.",
          offer_l4: "Can't see what you are looking for?",
          btnContact: "Contact us!",
          offer_techstack: "OUR TECHSTACK:",
          contact_title: "Contact us!",
          contact_question: "What can we do for you?",
          contact_ph_name:"Name",
          contact_ph_email:"Email",
          contact_ph_message:"Message",
          contact_gdpr:"I accept",
          contact_gdpr_link:"privacy policy",
          contact_slide_front: "Verified",
          contact_slide_back: "Slide to verify you are not a bot",
          contact_btn_submit: "Wyślij",
          contact_desc: "At Encoded Legal Solutions, we embrace various collaboration models to cater to your unique needs. Whether you prefer a one-time payment for a specific project or envision a partnership where we handle the software development while you contribute specialized knowledge, we're open to diverse collaboration structures. Let's discuss the possibilities and create a tailored arrangement that suits your objectives. Contact us today to initiate a conversation about how we can collaborate to drive innovation and success together.",
          footer_desc: "Encoded Legal Solutions is a leading software house dedicated to crafting bespoke software solutions for a diverse clientele. From legal firms to individual professionals, we specialize in developing custom-built software tailored to meet the unique needs of legal industry."
        }
      },
      pl: {
        translation: {
          nav_about: "O nas",
          nav_projects: "Projekty",
          nav_offer: "Oferta",
          nav_contact: "Kontakt",
          hero_title: "Tworzymy przyszłość branży prawniczej!",
          hero_desc: "Odkryj nowe możliwości dzięki naszym innowacyjnym rozwiązaniom technologicznym, stworzonym z myślą o potrzebach branży prawniczej, która stale ewoluuje wymagając tym samym sprawnego dostosowywania się do zachodzących zmian oraz rosnących oczekiwań klientów. Sprawdź nasze produkty i otwórz drzwi do świata prawa, który jest prosty, przystępny i dostępny dla wszystkich.",
          hero_desc_mobile: "Odkryj nowe możliwości dzięki naszym innowacyjnym rozwiązaniom technologicznym, zaprojektowanym z myślą o potrzebach branży prawniczej, która stale ewoluuje wymagając tym samym sprawnego dostosowywania się do ciągłych zmian i rosnących oczekiwań klientów.",
          about_title: "O nas",
          about_text1: "W Encoded Legal Solutions kreatywność spotyka się z technologią, tworząc rozwiązania, które są intuicyjne dla każdego użytkownika naszych aplikacji. Nasz zespół pasjonatów nie tylko integruje zaawansowane technologie sztucznej inteligencji, ale dba również o to, aby miały one praktyczne zastosowanie zwiększające jakość i szybkość codziennej pracy.",
          about_text3: "Rozwiązania, które oferujemy, adresowane są do różnorodnych klientów, włączając w to kancelarie oraz działy prawne przedsiębiorstw, jak również osoby indywidualne, które nie są związane na co dzień z prawem, lecz poszukują prostych sposobów na codzienne funkcjonowanie w zawiłym świecie pełnym skomplikowanych przepisów.", 
          about__found_title: "Nasze fundamenty",
          about_btn_ld: "Legal design",
          about_btn_pa: "Paperless",
          about_btn_si: "Prostota",
          about_btn_prac: "Praktyka",
          about_desc_ld: "Chcemy dostarczać narzędzia, które umożliwiają ich użytkownikom tworzenie przejrzystych i zrozumiałych dokumentów prawnych, zgodnych z obowiązującymi przepisami. W przeciwieństwie do wielu obecnych umów, które często cechuje niejasność i zawiłość, stawiamy na prostotę i klarowność.",
          about_desc_pa: "Oferujemy narzędzia, które umożliwiają naszym klientom przechowywanie, przetwarzanie i podpisywanie dokumentów w formie cyfrowej, oszczędzając tym samym czas pracowników, zmniejszając koszty związane z obiegiem dokumentacji w firmie oraz redukując negatywny wpływ na środowisko.",
          about_desc_si: "Tworzymy intuicyjne i łatwe w użyciu interfejsy, wolne od niepotrzebnych funkcjonalności. Dążymy do zapewnienie przyjemnego i bezproblemowego doświadczenia dla każdego użytkownika, niezależnie od jego poziomu doświadczenia.",
          about_desc_prac: "Naszym celem jest dostarczanie praktycznych rozwiązań, które skutecznie rozwiązują bieżące problemy i znacząco usprawniają pracę. Nie dajemy się wciągnąć w technologiczny szum, zajmując się rozwijaniem technologii, które naprawdę mają znaczenie dla naszych klientów.",
          projects_title: "Nasze projekty",
          projects_vt_title: "Verifytool",
          projects_vt_desc: "Usprawnij proces podejmowania decyzji dzięki verifytool.io, naszemu opartemu na sztucznej inteligencji narzędziu dostarczającemu kompleksowych informacji o firmie, w tym istotnych danych finansowych. Zdobądź przewagę konkurencyjną dzięki wydajnej analizie biznesowej.",
          projects_lh_title: "Legal Hire Network",
          projects_lh_desc: "Legal Hire Network to wyspecjalizowany portal obsługujący wyłącznie branżę prawniczą. Rewolucjonizuje rekrutację i współpracę, oferując platformę do outsourcingu spraw prawnych do wysoko wyspecjalizowanych firm prawniczych.",
          projects_btn: "Dowiedz się więcej",
          offer_title: "Nasza oferta",
          offer_wa: "Tworzenie aplikacji",
          offer_wa_desc: "Nasz zespół specjalizuje się w tworzeniu dynamicznych, zorientowanych na użytkownika aplikacji internetowych dostosowanych do specyficznych wymagań branży prawniczej.",
          offer_mb: "Rozwój aplikacji mobilnych",
          offer_mb_desc: "Specjalizujemy się w tworzeniu intuicyjnych i bogatych w funkcje aplikacji mobilnych na platformy Android i iOS",
          offer_l4: "Nie widzisz tego, czego szukasz?",
          btnContact: "Skontaktuj się z nami!",
          offer_techstack: "TECHNOLOGIE:",
          contact_title: "Skontaktuj się z nami!",
          contact_question: "Co możemy dla Ciebie zrobić?",
          contact_ph_name: "Imię",
          contact_ph_email: "Email",
          contact_ph_message: "Wiadomość",
          contact_gdpr: "Akceptuję",
          contact_gdpr_link: "politykę prywatności",
          contact_slide_front: "Zweryfikowano",
          contact_slide_back: "Przesuń, aby zweryfikować, że nie jesteś botem",
          contact_btn_submit: "Wyślij",
          contact_desc: "W Encoded Legal Solutions stosujemy różne modele współpracy, aby zaspokoić Twoje unikalne potrzeby. Niezależnie od tego, czy preferujesz jednorazową płatność za konkretny projekt, czy też wyobrażasz sobie partnerstwo, w którym my zajmujemy się rozwojem oprogramowania, a Ty wnosisz specjalistyczną wiedzę, jesteśmy otwarci na różne struktury współpracy. Przedyskutujmy możliwości i stwórzmy układ dostosowany do Twoich celów. Skontaktuj się z nami już dziś, aby rozpocząć rozmowę o tym, jak możemy współpracować, aby wspólnie wprowadzać innowacje i odnosić sukcesy",
          footer_desc: "Encoded Legal Solutions to wiodąca firma zajmująca się tworzeniem oprogramowania na zamówienie dla różnych klientów. Od firm prawniczych po indywidualnych profesjonalistów, specjalizujemy się w opracowywaniu niestandardowego oprogramowania dostosowanego do unikalnych potrzeb branży prawniczej."
        }
      }
    }
  });

export default i18n;
