import React, { useEffect} from 'react';

import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

export default function Projects() {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    const {t, i18n} = useTranslation();

    useEffect(() => {
      const lng = navigator.language;
      i18n.changeLanguage(lng);
      
    }, []);

        
    useEffect(() => {
        if (inView) {
        controls.start("visible");
        } else {
        controls.start("hidden");
        }
    }, [controls, inView]);


  return (
    <section className='projects' id="projects">
    <h2 className='projects__title link3'>{t("projects_title")}</h2>
    {/* usunąlem heighta */}
    <div ref={ref} style={{width: '100%'}}>
        <motion.div
            initial="hidden"
            animate={controls}
            variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: '100%' }
            }}
            transition={{ duration: 1 }}
        >
           
        <div className='projects__container'>
            <div className='projects__card'>
              
                <img className='projects__img' src={require('../img/LOGOVF.png')}/>
                <div className='card__txt'>
                    <h3 className='projects__card-title'>Verifytool</h3>
                    <p className='projects__desc'>{t("projects_vt_desc")}</p>
                    <button className='projects__button'> <a style={{textDecoration: "none", color: "#fff"}} href="https://verifytool.io" target="_blank" rel="noopener noreferrer">{t("projects_btn")}</a></button>
                </div>
            </div>
          
            <div className='projects__card'>
               
                <img className='projects__img' style={{}} src={require('../img/legalhire.png')}/>
                <div className='card__txt'>
                    <h3 className='projects__card-title'>Legal Hire Network</h3>
                    <p className='projects__desc'>{t("projects_lh_desc")}</p>
                    <button className='projects__button'><a style={{textDecoration: "none", color: "#fff"}} href="https://legalhire.net" target="_blank" rel="noopener noreferrer">{t("projects_btn")}</a></button>
                </div>
            </div> 
           
        </div>
        </motion.div>
    </div>




       
    </section>
  )
}
