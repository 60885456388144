import logo from './logo.svg';
import './App.css';
import Hero from './components/hero';
import Navbar from './components/navbar';
import AboutUs from './components/aboutus';
import Projects from './components/project';
import Offer from './components/offer';
import Contact from './components/contact';
import Footer from './components/footer';
import { useRef } from 'react';
import LeftSlideInOnScroll from './components/scrolltest';
import FadeInOnScroll from './components/fadeInonscroll';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';



function App() {

    // Tworzenie refów dla każdego komponentu, do którego chcesz przewijać
    const heroRef = useRef(null);
    const aboutRef = useRef(null);
    const projectsRef = useRef(null);
    const offerRef = useRef(null);
    const contactRef = useRef(null);


    const { i18n, t } = useTranslation();
    const [chLang, setChLang] = useState(navigator.language);

    useEffect(() => {
      i18n.changeLanguage(chLang);
    }, [chLang]); 


    const scrollToRef = (refName) => {

      console.log(refName);
      switch (refName) {
        case "heroRef":
          window.scrollTo({
            top: heroRef.current.offsetTop,
            behavior: "smooth"
          });
          break;
        case "aboutRef":
          window.scrollTo({
            top: aboutRef.current.offsetTop,
            behavior: "smooth"
          });
          break;
        case "projectsRef":
          window.scrollTo({
            top: projectsRef.current.offsetTop,
            behavior: "smooth"
          });
          break;
        case "offerRef":
          window.scrollTo({
            top: offerRef.current.offsetTop,
            behavior: "smooth"
          });
          break;
        case "contactRef":
          window.scrollTo({
            top: contactRef.current.offsetTop,
            behavior: "smooth"
          });
          break;
        default:
          break;
      }
    };


  return (
    <div  style={{overflowX: "hidden"}}>
    <Navbar scrollToRef={scrollToRef} setChLang={setChLang} chLang={chLang} t={t}/>
      <Hero ref={heroRef} />
      <AboutUs ref={aboutRef} />
      <Projects ref={projectsRef} />
      <Offer ref={offerRef} />
      <Contact ref={contactRef} />
      <Footer />
    </div>
  );
}

export default App;
