import React, { useEffect, useState } from 'react';
import Tabs from './tabs'
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';

export default function AboutUs() {

  const {t, i18n} = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
    
  }, []);


    const controls = useAnimation();
    const [ref, inView] = useInView();

    const [toggleState, setToggleState] = useState("ld");

    const options = [
      { value: "ld", label: "Legal design"},
      { value: "pl", label: "Paperless"},
      {value: "sc", label: "Prostota"},
      {value: "pr", label: "Praktyczność"},
  ];

  const handleChange = (selectedOption) =>  {
    setToggleState(selectedOption.value);
  }
    useEffect(() => {
        if (inView) {
          controls.start("visible");
        } else {
          controls.start("hidden");
        }
      }, [controls, inView]);


  return (
    <section className='about' id='about'>
      {/* usunolem z tad height nie wiem jak animacja */}
        <div ref={ref} style={{width: '100%', }}>
        <motion.div
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 }
        }}
        transition={{ duration: 3 }}
        style={{ opacity: 0 }} // ustawienie początkowej przezroczystości na 0
        >
        <div className='about__container'>
            <div className='about__gallery'>
                <div className='about__img--gradient'>
                    <img className='about__img' src={require('../img/about-us1.jpg')}/>
                </div>
                <div className='about__img--gradient'>
                    <img className='about__img' src={require('../img/about-us2.jpg')}/>
                </div>
                <div className='about__img--gradient'>
                <img className='about__img' src={require('../img/about-us4.jpg')}/>
                </div>
                <div className='about__img--gradient'>
                <img className='about__img' src={require('../img/about-us3.jpg')}/>
                </div>
            </div>
            <div className='about__text'>
                <h2 className='about__title link3'>{t("about_title")}</h2>
                <p className='about__description'>{t("about_text1")}</p>
                <p className='about__description'>{t("about_text3")}</p>
                <h3 className='about__description-fund' style={{marginTop: '1rem'}}>{t("about__found_title")}</h3>
                <div className='' style={{width: "100%", marginTop: "1rem"}}>
                  {/* poprawic */}
                <Select options={options} defaultValue={options[0].value || 'Select'} placeholder="Legal Design" onChange={handleChange}/>
                </div> 
                {/* tabs mobile */}
                {/* <Tabs className="mobile-hide" /> */}

                <div className='content-tabs' style={{border: "1px solid black", marginTop: "1rem", borderRadius: "4px"}}>
            <div className={toggleState === "ld" ? "content active-content" : "content"}>
                <span className='mobile-foundations-title'>Legal Design</span>
                <p className='tab-text'>
                {t("about_desc_ld")}
                </p>
            </div>
            <div className={toggleState === "pl" ? "content active-content" : "content"}>
                <span className='mobile-foundations-title'>Paperless</span>
                <p className='tab-text'>
                {t("about_desc_pa")}
                </p>
            </div>
            <div className={toggleState === "sc" ? "content active-content" : "content"}>
                <span className='mobile-foundations-title'>{t("about_btn_si")}</span>
                <p className='tab-text'>
                {t("about_desc_si")}
                </p>
            </div>
            <div className={toggleState === "pr" ? "content active-content" : "content"}>
                <span className='mobile-foundations-title'>{t("about_btn_prac")}</span>
                <p className='tab-text'>
                {t("about_desc_prac")}
                </p>
            </div>
        </div>
            </div>
        </div>

      </motion.div>
      </div>
    </section>
  )
}
