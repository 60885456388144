import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import SlideButton from 'react-slide-button'
import { useTranslation } from 'react-i18next';

export default function Contact() {

  const {t, i18n} = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
    
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState('');
  const [wiadomosc, setWiadomosc] = useState('');
  // Stany przechowujące błędy walidacji
  const [emailError, setEmailError] = useState('');
  const [wiadomoscError, setWiadomoscError] = useState('');

  // Funkcja do sprawdzania poprawności adresu e-mail
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const [verified, setVerified] = useState(false);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  // Obsługa wysłania formularza
  const handleSubmit = async (e) => {
    console.log("tetsf")
    // Walidacja pola e-mail
    if (!validateEmail(email)) {
      setEmailError('Nieprawidłowy adres e-mail');
      return;
    } else {
      setEmailError('');
    }

    // Walidacja pola wiadomości
    if (wiadomosc.trim() === '') {
      setWiadomoscError('Wiadomość nie może być pusta');
      return;
    } else {
      setWiadomoscError('');
    }

    // Jeśli walidacja przebiegła pomyślnie, możesz tutaj wykonać inne akcje, np. wysłać dane
    console.log('Dane formularza:', { email, wiadomosc });

    await sendEmail(name, email, wiadomosc);
    // Wyczyszczenie pól formularza po wysłaniu
    setName('');
    setEmail('');
    setWiadomosc('');
  };

  const sendEmail = async (name, email, wiadomosc) => {
    console.log("ftonedncheck", name, email, wiadomosc);
    try {
      const response = await axios.get('https://worker-lucky-leaf-cbc7.mateuszhanak.workers.dev/sendEmail', {
        params: {
          name: name,
          email: email,
          text: wiadomosc
        }
      });
  
      console.log(response.data);
    } catch (error) {
      console.error('Błąd podczas wysyłania zapytania:', error);
    }
  };



  

  return (
    <div className='contact' id="contact">
      <div className='contact__content flex'>
        <div className='contact__left-col'>
          <h2 className='contact__title link3'>{t("contact_title")}</h2>
          <p className='contact__subtitle'>{t("contact_question")}</p>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: '-100%' }
            }}
            transition={{ duration: 1 }}
          >
            <div>
              <div>
                <input value={name} onChange={(e) => setName(e.target.value)}  placeholder={t("contact_ph_name")} required={true}></input>
              </div>
              <div>
                <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t("contact_ph_email")} required={true}></input>
              </div>
              <div>
                <textarea value={wiadomosc} onChange={(e) => setWiadomosc(e.target.value)} placeholder={t("contact_ph_message")} required={true}></textarea>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
    <input type="checkbox" style={{marginRight: "5px", width: "auto", display: "block"}}/>
    <p style={{margin: 0}}>{t("contact_gdpr")} <a style={{color: "#24335C", textDecoration: "none"}} href="https://drive.google.com/file/d/1Fi0Nz6DItJpxR80HUeHCZnRcULwMVGDD/view?usp=sharing" target="_blank" rel="noopener noreferrer">{t("contact_gdpr_link")}</a></p>
</div>

            <SlideButton  classList="slider"   caretClassList="my-caret-class"
  overlayClassList="my-overlay-class" mainText={t("contact_slide_back")} overlayText={t("contact_slide_front")} onSlideDone={function(){
              setVerified(true);
            }}/>
         <button className={`${verified ? 'btn--contact': 'btn--contact--disabled'}`} style={{marginTop: "1rem"}} onClick={verified && handleSubmit}>{t("contact_btn_submit")}</button>

          </motion.div>
        </div>
        <p className='contact__description'>{t("contact_desc")}</p>
      </div>
    </div>
  )
}
